<template>
  <div class="exit">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>退出</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="exit-flex">
      <img src="../../assets/logo.png" alt="">
      <h3>退出账号</h3>
      <p>您已成功退出了您的账户。</p>
      <p>您的购物车商品已保存,您下次登录可继续购物</p>
      <el-button>继续</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'exit',
  mounted () {
    localStorage.setItem('tokens', '')
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.exit {
  @include body;
  .exit-flex {
    @include flexCenter($direction: column, $justify: unset)
  }
}

</style>
